import * as React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';

interface Props {
  title: string;
  body?: string | null;
  expire?: string;
  canEdit?: boolean;
  onEdit?: () => void;
}

export const Note: React.FC<Props> = ({
  title,
  body,
  expire,
  canEdit,
  onEdit,
}) => {
  if (!body) {
    return null;
  }

  // TODO: api not jet implemented - update
  const expireString = expire ? ` (Expires in 6 months (27.02.2025))` : '';
  return (
    <Box>
      <Typography gutterBottom variant="h5">
        {title}
        {expireString}:
      </Typography>
      <Stack
        sx={{ marginBottom: 2 }}
        direction="row"
        alignItems="baseline"
        gap={1}
      >
        <Typography gutterBottom variant="body1">
          {body}
        </Typography>
        {canEdit && onEdit && (
          <Button
            size="small"
            onClick={() => {
              onEdit();
            }}
          >
            Edit
          </Button>
        )}
      </Stack>
    </Box>
  );
};
