import * as React from 'react';
import { Button, Stack } from '@mui/material';

import { interestService, userService } from '../api';
import { MultiSelect, OptionsType } from './MultiSelect';
import { UserInterestItem } from '../types';

interface Props {
  registerButtonRef?: React.RefObject<HTMLButtonElement>;
}

const groupByExpertise = (items: UserInterestItem[]) => {
  return items.reduce(
    (acc, item) => {
      const key = item.is_expertise ? 'expertise' : 'interest';
      acc[key].push(item.name);
      return acc;
    },
    { expertise: [] as string[], interest: [] as string[] },
  );
};

export const AreasSetting: React.FC<Props> = ({ registerButtonRef }) => {
  const { interestList } = interestService();
  const { updateUserInterest, userInterest } = userService();

  const [areasOfInterest, setAreasOfInterest] = React.useState<OptionsType>([]);
  const [areasOfExpertise, setAreasOfExpertise] = React.useState<OptionsType>(
    [],
  );

  React.useEffect(() => {
    // TODO: add filter for is_expertise - fix api

    if (userInterest) {
      const { interest, expertise } = groupByExpertise(userInterest);

      setAreasOfInterest(interest.map((title) => ({ title })));
      setAreasOfExpertise(expertise.map((title) => ({ title })));
    }
  }, [userInterest]);

  const handleInterestChange = (selectedOptions: OptionsType) => {
    setAreasOfInterest(selectedOptions);
    // handleUpdate(); // Auto update
  };

  const handleExpertiseChange = (selectedOptions: OptionsType) => {
    setAreasOfExpertise(selectedOptions);
    // handleUpdate(); // Auto update
  };

  const handleSave = () => {
    const interests = areasOfInterest.map(({ title }) => ({
      is_expertise: false,
      name: title,
    }));
    const expertise = areasOfExpertise.map(({ title }) => ({
      is_expertise: true,
      name: title,
    }));

    // TODO: will this api be split?
    updateUserInterest({
      interests: [...interests, ...expertise],
    });
  };

  return (
    <Stack spacing={2}>
      <MultiSelect
        label="Areas of interest"
        selected={areasOfInterest}
        optionList={interestList?.map(({ name }) => ({ title: name }))}
        onChange={handleInterestChange}
      />
      <MultiSelect
        label="Areas of expertise"
        selected={areasOfExpertise}
        optionList={interestList?.map(({ name }) => ({ title: name }))}
        onChange={handleExpertiseChange}
      />
      <Button onClick={handleSave} variant="contained">
        Save
      </Button>
    </Stack>
  );
};
