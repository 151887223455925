// https://elazizi.com/posts/react-query-auth-token-refresh/#simple-project-setup
import { type AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { AUTH_STORAGE_KEY } from '../appConfig';

import { api } from '../client';
import { useContext, useEffect } from 'react';
import { defaultSettings, UserContext } from '../context/userContext';
import { useSnackbar } from 'notistack';
import useLocalStorageState from 'use-local-storage-state';
import { useNavigate } from 'react-router-dom';
import { CommonStatusRes, LoginReq, LoginRes, LogoutReq } from '../types/';
import { queryClient } from '..';

const postLogin = ({ user, password }: LoginReq) => {
  return api.post<AxiosError, LoginRes>('/v1/login', { user, password });
};

const postLogout = ({ refresh_token }: LogoutReq) => {
  return api.post<AxiosError, CommonStatusRes>('/v1/logout', { refresh_token });
};

// export const fetchNewToken = async () => {
//   try {
//     const token: string = await api
//       .post('/v1/refresh')
//       .then((res) => res.data.token);
//     return token;
//   } catch (error) {
//     return null;
//   }
// };

// export const refreshAuth = async (failedRequest: any) => {
//   const newToken = await fetchNewToken();

//   if (newToken) {
//     failedRequest.response.config.headers.Authorization = 'Bearer ' + newToken;
//     // setHeaderToken(newToken);
//     // you can set your token in storage too
//     // setToken({ token: newToken });
//     return Promise.resolve(newToken);
//   } else {
//     // you can redirect to login page here
//     // router.push("/login");
//     return Promise.reject();
//   }
// };

export const authService = () => {
  const navigate = useNavigate();
  const { saveSettings } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [authStorage, setAuthStorage] = useLocalStorageState(AUTH_STORAGE_KEY, {
    defaultValue: defaultSettings,
  });

  const resetSettings = () => {
    const allSettings = {
      loggedIn: false,
      refreshToken: '',
      accessToken: '',
    };
    saveSettings(allSettings);
    setAuthStorage(allSettings);

    navigate('/');
  };

  useEffect(() => {
    if (authStorage) {
      saveSettings(authStorage);
    }
  }, [authStorage]);

  api.interceptors.response.use(
    function (response) {
      const authStorage = localStorage.getItem(AUTH_STORAGE_KEY);
      // const [authStorage, setAuthStorage] = useLocalStorageState('auth');
      // console.log('authStorage22: ', authStorage);
      // console.log('response1: ', response);
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Not authenticated
      if ([401, 403].includes(error?.status)) {
        resetSettings();
      }
      console.error(error);
      return Promise.reject(error);
    },
  );

  const loginMutation = useMutation({
    mutationFn: postLogin,
    onMutate: (variables) => {
      // console.log("variables: ", variables);
      // A mutation is about to happen!
      // // Optionally return a context containing data to use when for example rolling back
      // return { id: 1 };
    },
    onError: ({ message }, variables, context) => {
      enqueueSnackbar(`Authentication error: ${message}`, { variant: 'error' });
    },
    onSuccess: ({
      data: { access_token: accessToken, refresh_token: refreshToken },
    }) => {
      if (saveSettings && accessToken) {
        const allSettings = {
          loggedIn: true,
          refreshToken,
          accessToken,
        };
        saveSettings(allSettings);
        setAuthStorage(allSettings);
      }
    },
    onSettled: (data, error, variables, context) => {
      // Error or success... doesn't matter!
    },
  });

  const logoutMutation = useMutation({
    mutationFn: postLogout,
    onError: ({ message }) => {
      enqueueSnackbar(`Authentication error: ${message}`, { variant: 'error' });
    },
    onSuccess: () => {
      resetSettings();
      queryClient.clear();
    },
  });

  return { login: loginMutation.mutate, logout: logoutMutation.mutate };
};
