import * as React from 'react';
import { Alert, AlertTitle, Box } from '@mui/material';
import { Annotation } from '../types';

interface Props {
  annotation?: Annotation | null;
}

export const InconclusiveAnswer: React.FC<Props> = ({ annotation }) => {
  if (!annotation?.description || annotation?.grade == 'consistent') {
    return null;
  }

  return (
    <Box
      sx={{
        paddingLeft: '42px',
      }}
    >
      <Alert
        sx={{
          '& .MuiAlert-icon': {
            alignItems: 'center',
          },
          marginBottom: 2,
        }}
        severity="warning"
      >
        {' '}
        <AlertTitle>Inconclusive answer:</AlertTitle>
        {annotation?.description}
      </Alert>
    </Box>
  );
};
