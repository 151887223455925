import * as React from 'react';
import { reviewService, userService } from '../api';
import Box from '@mui/material/Box';
import {
  Button,
  Collapse,
  Drawer,
  IconButton,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { References } from '../molecules/References';
import { ListSkeleton } from '../molecules/Skeletons';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { InconclusiveAnswer } from '../molecules/InconclusiveAnswer';
import { Note } from '../molecules/Note';
import { SendMessage } from '../molecules/SendMessage';
import { Conversation } from './Conversation';
import CloseIcon from '@mui/icons-material/Close';
import zIndex from '@mui/material/styles/zIndex';
import { TitleSection } from '../molecules/Common';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { MuiMarkDown } from '../molecules';

export const Review: React.FC = () => {
  const { reviewId } = useParams();
  const { isSME } = userService();
  const navigate = useNavigate();
  const [showSideBar, setShowSideBar] = React.useState(false);
  const [showSendMessage, setShowSendMessage] = React.useState(false);

  // Close inline preview if reviewId changes
  React.useEffect(() => {
    setShowSideBar(false);
  }, [reviewId]);

  const listRef = React.useRef<HTMLDivElement>(null);

  if (!reviewId) {
    return null;
  }

  const { review, reviewsIsLoading } = reviewService(reviewId);

  const hasRevNote = !!review?.reviewer_note;

  const toggleSidebar = () => {
    setShowSideBar(!showSideBar);
  };

  React.useEffect(() => {
    setShowSendMessage(!hasRevNote);
  }, [hasRevNote]);

  if (!review) {
    return null;
  }

  const {
    answer,
    question,
    uid,
    source_documents,
    annotations,
    conversation_uid,
  } = review.qa;

  const handleViewConversation = () => {
    // navigate(`/conversation/${conversation_uid}`);
    toggleSidebar();
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Toolbar />

        <Paper>
          {reviewsIsLoading && (
            <Stack padding={2}>
              <ListSkeleton numOfItems={12} width="100%" />
            </Stack>
          )}
          <Stack key={uid} padding={2} spacing={3} ref={listRef}>
            <TitleSection
              title="Question:"
              icon={<AccountCircleIcon fontSize="large" />}
            >
              <Typography fontSize={18} variant="body1">
                {question}
              </Typography>
            </TitleSection>

            <TitleSection
              title="Answer:"
              icon={<LocalPoliceIcon fontSize="large" />}
            >
              <MuiMarkDown key={uid} mdContent={answer} />
            </TitleSection>

            <References sourceDocuments={source_documents} />

            <InconclusiveAnswer annotation={annotations} />

            <Note
              title={
                isSME
                  ? `${review.requester_name || "User's"} Note`
                  : 'Your Note'
              }
              body={review.requester_note}
            />

            <Collapse in={!showSendMessage} timeout="auto" unmountOnExit>
              <Note
                title={
                  isSME ? 'Your Answer' : `Answer by ${review.reviewer_name}`
                }
                body={review.reviewer_note}
                canEdit={isSME}
                onEdit={() => {
                  setShowSendMessage(true);
                }}
              />
            </Collapse>

            <Collapse in={showSendMessage} timeout="auto" unmountOnExit>
              <SendMessage
                reviewId={reviewId}
                value={review.reviewer_note}
                onClose={() => {
                  setShowSendMessage(false);
                }}
              />
            </Collapse>

            {isSME && (
              <Box display="flex" justifyContent="center">
                <Button
                  onClick={handleViewConversation}
                  sx={{ marginBottom: 2 }}
                  variant="contained"
                >
                  View full conversation
                </Button>
              </Box>
            )}
          </Stack>
        </Paper>
      </Box>

      <Drawer
        ModalProps={{}}
        sx={{
          zIndex: zIndex.drawer + 1,
          // width: 600,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '80%',
          },
        }}
        anchor="right"
        open={showSideBar}
        onClose={toggleSidebar}
      >
        <Toolbar
          sx={{
            position: 'fixed',
            top: 0,
            background: '#2a91cc',
            width: '100%',
            zIndex: 1,
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {/* <Typography noWrap variant="h6">
              Whole conversation
            </Typography> */}
            <IconButton
              size="large"
              edge="start"
              color="default"
              aria-label="menu"
              onClick={toggleSidebar}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Toolbar>

        <Conversation uid={conversation_uid} />
      </Drawer>
    </>
  );
};
