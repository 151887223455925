import * as React from 'react';
import { UserContext } from '../context/userContext';
import { Collapse, IconButton, Paper, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { ConversationItemQas } from '../types';
import { References } from '../molecules/References';
import { ContactSme } from '../molecules/ContactSme';
import { FollowUpQuestions } from '../molecules/FollowUpQuestions';
import { InconclusiveAnswer } from '../molecules/InconclusiveAnswer';
import { TitleSection } from '../molecules/Common';
import { FeedBack } from '../molecules/Feedback';
import { MuiMarkDown } from '../molecules';
import { format } from 'date-fns';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

interface Props {
  data: ConversationItemQas;
  onFollowUpQuestion: (question: string) => void;
  conversationId: string;
  number: number;
  collapsed?: boolean;
}

export const ConversationItem: React.FC<Props> = ({
  data: {
    answer,
    question,
    uid: quid,
    source_documents,
    review_uid,
    follow_up_questions,
    annotations,
    feedback_lickert,
    feedback_comment,
    created_at,
  },
  onFollowUpQuestion,
  conversationId,
  number,
  collapsed = false,
}) => {
  const { loggedIn } = React.useContext(UserContext);
  const [isExpanded, setIsExpanded] = React.useState(!collapsed);

  React.useEffect(() => {
    setIsExpanded(!collapsed);
  }, [collapsed]);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return loggedIn ? (
    <Stack key={quid} direction="row" gap={1}>
      {number}
      <Paper sx={{ width: '100%' }}>
        <Stack spacing={2} padding={2}>
          <Stack direction="row" justifyContent="space-between">
            <TitleSection
              title={question}
              icon={<AccountCircleIcon fontSize="large" />}
            ></TitleSection>
            <IconButton onClick={handleToggle} aria-label="delete" size="small">
              {isExpanded ? (
                <ExpandLess fontSize="inherit" />
              ) : (
                <ExpandMoreIcon fontSize="inherit" />
              )}
            </IconButton>
          </Stack>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Grid container spacing={2}>
              <Grid size={6}>
                <Stack gap={2}>
                  <TitleSection
                    title={format(created_at, 'MMMM do, yyyy HH:mm')}
                    icon={<LocalPoliceIcon fontSize="large" />}
                  >
                    <MuiMarkDown key={quid} mdContent={answer} />
                  </TitleSection>

                  <InconclusiveAnswer annotation={annotations} />

                  <FeedBack
                    uid={conversationId}
                    quid={quid}
                    defaultValue={feedback_lickert}
                    defaultComment={feedback_comment}
                  />
                </Stack>
              </Grid>
              <Grid size={6}>
                <Stack gap={2}>
                  <FollowUpQuestions
                    questions={follow_up_questions}
                    onClick={onFollowUpQuestion}
                  />

                  <References sourceDocuments={source_documents} />

                  <ContactSme quid={quid} reviewUid={review_uid} />
                </Stack>
              </Grid>
            </Grid>
          </Collapse>
        </Stack>
      </Paper>
    </Stack>
  ) : null;
};
