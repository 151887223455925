// https://elazizi.com/posts/react-query-auth-token-refresh/#simple-project-setup
import type { AxiosError } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';

import { api } from '../client';

import {
  CommonStatusRes,
  GetReviewUidReq,
  ReviewAnswerReq,
  ReviewReq,
  ReviewRequestedRes,
  ReviewRes,
} from '../types/';
import { enqueueSnackbar } from 'notistack';
import { UserContext } from '../context/userContext';
import React from 'react';

const getReview = ({ uid }: GetReviewUidReq) => {
  return api.get<AxiosError, ReviewRes>(`/v1/review/${uid}`);
};

const getRequestedReviews = () => {
  return api.get<AxiosError, ReviewRequestedRes>('/v1/review/requested');
};

const getAssignedReviews = () => {
  return api.get<AxiosError, ReviewRequestedRes>('/v1/review/assigned');
};

const postReview = (reqData: ReviewReq) => {
  return api.post<AxiosError, ReviewRes>(`/v1/review`, reqData);
};

const postReviewAnswer = ({ uid, reviewer_note }: ReviewAnswerReq) => {
  return api.post<AxiosError, CommonStatusRes>(`/v1/review/${uid}/answer`, {
    reviewer_note,
  });
};

export const reviewService = (uid = '') => {
  const { loggedIn } = React.useContext(UserContext);

  const review = useQuery({
    queryKey: ['review', uid],
    queryFn: () => getReview({ uid }),
    enabled: !!uid,
    // queryFn: ({ queryKey }) => getReview(queryKey[1]),
  });

  const requestedReviews = useQuery({
    queryKey: ['requestedReviews'],
    queryFn: getRequestedReviews,
    enabled: loggedIn,
  });

  const assignedReviews = useQuery({
    queryKey: ['assignedReviews'],
    queryFn: getAssignedReviews,
    enabled: loggedIn,
  });

  const reviewMutation = useMutation({
    mutationFn: postReview,
    onError: ({ message }) => {
      enqueueSnackbar(`requestedReviews error: ${message}`, {
        variant: 'error',
      });
    },
    onSuccess: () => {},
  });

  const reviewAnswerMutation = useMutation({
    mutationFn: postReviewAnswer,
    onError: ({ message }) => {
      enqueueSnackbar(`postReviewAnswer error: ${message}`, {
        variant: 'error',
      });
    },
    onSuccess: () => {},
  });

  return {
    review: review.data?.data,
    reviewRefetch: review.refetch,
    requestedReviewsList: requestedReviews.data?.data.reviews,
    requestedReviewsRefetch: requestedReviews.refetch,
    assignedReviewsList: assignedReviews.data?.data.reviews,
    assignedReviewsRefetch: assignedReviews.refetch,
    reviewsIsLoading: assignedReviews.isLoading || requestedReviews.isLoading,
    sendReview: reviewMutation.mutateAsync,
    reviewResponse: reviewMutation.data?.data,
    reviewIsPending: reviewMutation.isPending,
    answerReview: reviewAnswerMutation.mutateAsync,
    answerReviewIsPending: reviewAnswerMutation.isPending,
  };
};
