import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { authService, userService } from '../api';
import { useEffect } from 'react';
import { UserContext } from '../context/userContext';
import { AccountCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface Props {
  onRegisterClick?: () => void;
  onResetClick?: () => void;
}

export const Login: React.FC<Props> = ({ onRegisterClick, onResetClick }) => {
  const { login, logout } = authService();
  const { userInfo } = userService();
  const [user, setUser] = React.useState<null | string>(null);
  const [password, setPassword] = React.useState<null | string>(null);
  const [showLoginForm, setShowLoginForm] = React.useState(false);
  const navigate = useNavigate();

  const handleOpen = () => setShowLoginForm(true);
  const handleClose = () => {
    setShowLoginForm(false);
    setAnchorEl(null);
  };

  const { loggedIn, refreshToken, accessToken } = React.useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    if (loggedIn) {
      handleClose();
    } else {
      setShowLoginForm(true);
    }
  }, [loggedIn]);

  const handleLogin = () => {
    if (user && password) {
      login({ user, password });
    }
  };

  const handleRegister = () => {
    if (onRegisterClick) {
      setShowLoginForm(false);
      onRegisterClick();
    }
  };

  const handleReset = () => {
    if (onResetClick) {
      setShowLoginForm(false);
      onResetClick();
    }
  };

  const handleLogout = () => {
    logout({ refresh_token: refreshToken });
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const hideLogin = !(user && password);

  return (
    <>
      {!loggedIn && (
        <Button color="inherit" onClick={handleOpen} variant="text">
          Log In
        </Button>

        // Don't have an account? Register

        // Forgot password?
      )}

      {loggedIn && (
        <>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle fontSize="large" />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem disabled onClick={handleClose}>
              {userInfo?.first_name
                ? `${userInfo?.first_name} ${userInfo?.last_name} :`
                : 'User type: '}
              {` ${userInfo?.user_type}`}
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate('/settings');
              }}
            >
              Areas settings
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </>
      )}
      <Dialog onClose={handleClose} open={showLoginForm}>
        <DialogTitle>Sign in</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <DialogContentText>
              Welcome user, please sign in to continue
            </DialogContentText>
            <TextField
              value={user}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setUser(event.target.value);
              }}
              id="user"
              label="User name"
            />
            <TextField
              type="password"
              value={password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(event.target.value);
              }}
              id="password"
              label="Password"
            />
            <DialogContentText>
              <Box>
                You don't have an account,
                <Button onClick={handleRegister} size="small" variant="text">
                  Register
                </Button>
              </Box>
              <Box>
                Did you forgot you password,
                <Button onClick={handleReset} size="small" variant="text">
                  Reset password
                </Button>
              </Box>
            </DialogContentText>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            autoFocus
            disabled={hideLogin}
            variant="contained"
            onClick={handleLogin}
          >
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
