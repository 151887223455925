import * as React from 'react';
import { UserContext } from '../context/userContext';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material';
import ReviewsIcon from '@mui/icons-material/Reviews';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PreviewIcon from '@mui/icons-material/Preview';

import { reviewService, userService } from '../api';
import { ListSkeleton } from './Skeletons';
import { NavLink } from 'react-router-dom';

export const ReviewsMenu: React.FC = () => {
  const { isSME } = userService();
  const { loggedIn } = React.useContext(UserContext);
  const [open, setOpen] = React.useState(true);

  const { requestedReviewsList, reviewsIsLoading, assignedReviewsList } =
    reviewService();

  const handleClick = () => {
    setOpen(!open);
  };

  const list = isSME ? assignedReviewsList : requestedReviewsList;

  return loggedIn ? (
    <List dense>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon sx={{ minWidth: 40 }}>
          <ReviewsIcon />
        </ListItemIcon>
        <ListItemText primary={isSME ? 'To Do' : 'Review requests'} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {reviewsIsLoading && <ListSkeleton contrast />}
        <List
          sx={{
            '.active': {
              bgcolor: '#dfdfdf',
              // '&, & .MuiListItemIcon-root': {
              //   color: 'pink',
              // },
            },
          }}
          dense
          component="div"
          disablePadding
        >
          {list?.map(({ uid, requester_note }) => (
            <ListItem
              component={NavLink}
              to={`/review/${uid}`}
              key={uid}
              disablePadding
            >
              <ListItemButton sx={{ pl: 3 }}>
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <PreviewIcon />
                </ListItemIcon>
                <ListItemText primary={requester_note} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </List>
  ) : null;
};
