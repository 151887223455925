import * as React from 'react';
import Box from '@mui/material/Box';

import { Conversation } from './components/Conversation';
import { CssBaseline } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { SideMenu } from './molecules/SideMenu';
import { SnackbarProvider } from 'notistack';
import { Home } from './components/Home';
import { Review } from './components/Review';
import { grey } from '@mui/material/colors';
import { Settings } from './components/Settings';

// Create a client

export default function App() {
  return (
    <SnackbarProvider maxSnack={5}>
      <React.Suspense fallback="Loading">
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />

          <SideMenu />

          <Box component="main" sx={{ flexGrow: 1 }} bgcolor={grey[300]}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="conversation/:conversationId"
                element={<Conversation />}
              />
              <Route path="review/:reviewId" element={<Review />} />
              <Route path="settings" element={<Settings />} />
            </Routes>
          </Box>
        </Box>
      </React.Suspense>
    </SnackbarProvider>
  );
}
