import * as React from 'react';
import { UserContext } from '../context/userContext';

import Box from '@mui/material/Box';
import { Card, CardContent, CardHeader, Toolbar } from '@mui/material';
import { AreasSetting } from '../molecules/AreasSetting';

export const Settings: React.FC = () => {
  const { loggedIn } = React.useContext(UserContext);

  return loggedIn ? (
    <Box
      component="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        flexGrow: 1,
        p: 3,
      }}
    >
      <Toolbar />

      <Card sx={{ minWidth: 400 }}>
        <CardHeader
          title="Areas"
          subheader="Please select at least one area."
        />
        <CardContent>
          <AreasSetting />
        </CardContent>
        {/* <CardActions>
          <Button size="small">Save</Button>
        </CardActions> */}
      </Card>
    </Box>
  ) : null;
};
