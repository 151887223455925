import axios from 'axios';
import { AUTH_STORAGE_KEY } from './appConfig';
import { UserContextSettings } from './types';

export const api = axios.create({
  baseURL: window.APP_CONFIG?.REACT_APP_API_URL || process.env.REACT_APP_API_URL,
});

// Handle Token on all requests
api.interceptors.request.use(
  (request) => {
    const authStorage = localStorage.getItem(AUTH_STORAGE_KEY);
    if (authStorage) {
      const { accessToken } = JSON.parse(authStorage) as UserContextSettings;
      request.headers.Authorization = `Bearer ${accessToken}`;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);
