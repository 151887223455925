import * as React from 'react';
import Box from '@mui/material/Box';
import { UserContext } from '../context/userContext';
import {
  AppBar,
  Chip,
  IconButton,
  Paper,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { conversationService } from '../api';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ConversationItemQas } from '../types';
import { BotInput } from '../molecules/BotInput';
import { ListSkeleton } from '../molecules/Skeletons';
import { grey } from '@mui/material/colors';
import { ConversationItem } from '../molecules';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

interface Props {
  uid?: string | null;
}

export const Conversation: React.FC<Props> = ({ uid }) => {
  const { loggedIn } = React.useContext(UserContext);
  const listRef = useRef<HTMLDivElement>(null);

  const { conversationId = uid } = useParams();
  const isInlineView = !!uid;

  if (!conversationId) {
    return null;
  }

  const {
    getConversationQas,
    sendQuestion,
    conversationResponse,
    conversationIsPending,
    questionResponse,
    questionIsPending,
  } = conversationService();

  const {
    qas: conversationQas,
    chroma_db_filenames: chips,
    name: questionAsked,
  } = conversationResponse || {};

  const [askedQuestion, setAskedQuestion] = React.useState('');
  const [currentSessionQuestions, setCurrentSessionQuestions] = React.useState<
    ConversationItemQas[]
  >([]);
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const handleUpdate = () => {
    getConversationQas({ uid: conversationId });
  };

  useEffect(() => {
    getConversationQas({ uid: conversationId });
    // Clear state
    setCurrentSessionQuestions([]);
    setIsCollapsed(false);
  }, [conversationId]);

  useEffect(() => {
    if (questionResponse && currentSessionQuestions) {
      setCurrentSessionQuestions([
        ...currentSessionQuestions,
        questionResponse,
      ]);
    }
  }, [questionResponse]);

  // After question returned
  useEffect(() => {
    listRef.current?.lastElementChild?.scrollIntoView({
      behavior: 'smooth',
    });
    setAskedQuestion('');
  }, [conversationQas, currentSessionQuestions]);

  const handleSubmitQuestion = (question: string) => {
    setAskedQuestion(question);
    listRef.current?.lastElementChild?.scrollIntoView({
      behavior: 'smooth',
    });

    sendQuestion({ uid: conversationId, prompt: question });
  };

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleFollowUpQuestion = handleSubmitQuestion;

  const allAnswers = [
    ...currentSessionQuestions,
    ...(conversationQas || []),
  ].sort(({ created_at: cr1 }, { created_at: cr2 }) => {
    return cr1.localeCompare(cr2);
  });

  return loggedIn ? (
    <>
      <AppBar position="sticky">
        <Toolbar
          sx={{
            background: '#dfdfdf',
          }}
        />
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            background: '#dfdfdf',
          }}
        >
          <Stack spacing={1} direction="row" alignItems="center">
            <Tooltip title={questionAsked}>
              <Typography
                variant="h6"
                sx={{
                  color: 'black',
                  maxWidth: '600px',
                  minWidth: 0,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {questionAsked}
              </Typography>
            </Tooltip>
            {chips &&
              chips.map((chipLabel) => (
                <Chip
                  key={chipLabel}
                  label={chipLabel}
                  size="small"
                  variant="outlined"
                  sx={{
                    background: 'white',
                  }}
                />
              ))}
          </Stack>
          <IconButton
            sx={{
              color: 'black',
              // flex: 1,
              // minWidth: 0,
            }}
            onClick={handleToggle}
            aria-label="delete"
            size="small"
          >
            {isCollapsed ? (
              <KeyboardDoubleArrowDownIcon fontSize="large" />
            ) : (
              <KeyboardDoubleArrowUpIcon fontSize="large" />
            )}
          </IconButton>
        </Toolbar>
        {/* <Toolbar />
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                // onClick={handleDrawerToggle}KeyboardDoubleArrowUpIcon
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <KeyboardDoubleArrowUpIcon />
              </IconButton>
            </Toolbar> */}
      </AppBar>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
        bgcolor={grey[300]}
      >
        {conversationIsPending && (
          <Paper>
            <Stack padding={2}>
              <ListSkeleton numOfItems={12} width="100%" />
            </Stack>
          </Paper>
        )}

        <Box position="sticky" bottom={16}></Box>
        {!conversationIsPending && (
          <Stack spacing={2}>
            <Stack spacing={2} ref={listRef}>
              {allAnswers?.map((data, i) => (
                <ConversationItem
                  data={data}
                  conversationId={conversationId}
                  onFollowUpQuestion={handleFollowUpQuestion}
                  number={i + 1}
                  collapsed={isCollapsed}
                ></ConversationItem>
              ))}
            </Stack>
            <BotInput
              value={askedQuestion}
              loading={questionIsPending}
              onSubmit={handleSubmitQuestion}
              // chips={chips}
            />
          </Stack>
        )}
      </Box>
    </>
  ) : null;
};
