import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import { UserProvider } from './context/userContext';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);
export const queryClient = new QueryClient();

root.render(
  <UserProvider>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          {/* <ReactQueryRewind /> */}
          <App />
        </QueryClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  </UserProvider>,
);
