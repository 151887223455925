import * as React from 'react';
import { List, ListItem, Skeleton } from '@mui/material';

interface Props {
  numOfItems?: number;
  width?: string;
  contrast?: boolean;
}

export const ListSkeleton: React.FC<Props> = ({
  numOfItems = 3,
  width = '80%',
  contrast,
}) => {
  return (
    <List component="div" disablePadding>
      {Array.from(Array(numOfItems).keys()).map((val, i) => (
        <ListItem key={i}>
          <Skeleton
            sx={{
              ...(contrast ? { bgcolor: 'grey.400' } : {}),
            }}
            width={width}
            animation={false}
          />
        </ListItem>
      ))}
    </List>
  );
};
