import * as React from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';

interface Props extends React.PropsWithChildren {
  title: string;
  icon?: React.ReactElement;
  contentPadding?: string;
}

export const TitleSection: React.FC<Props> = ({
  title,
  icon,
  children,
  contentPadding,
}) => {
  if (!title) {
    return null;
  }

  return (
    <Box>
      <Stack direction="row" marginBottom={0} alignItems="center" gap={1}>
        {icon}

        <Typography variant="h6">{title}</Typography>
      </Stack>
      <Box
        sx={{
          paddingLeft: contentPadding || '42px',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
