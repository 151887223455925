import * as React from 'react';
import {
  Alert,
  AlertTitle,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { UserContext } from '../context/userContext';
import { userService } from '../api';

import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { UserType } from '../types';
import { getUrlPath } from '../utils';

interface Props {
  hideButton?: boolean;
  buttonRef?: React.RefObject<HTMLButtonElement>;
}

interface FormInputs {
  first_name: string;
  last_name: string;
  email: string;
  company: string;
  user_type: UserType;
  password: string;
}

const typeOptions = [
  {
    id: 'REGULAR',
    label: 'Regular user',
  },
  {
    id: 'SME',
    label: 'SME user',
  },
];

export const Reset: React.FC<Props> = ({ hideButton = false, buttonRef }) => {
  const { resetPassword } = userService();
  const formContext = useForm<FormInputs>({
    defaultValues: {
      first_name: '',
      last_name: '',
      company: '',
      user_type: 'REGULAR',
      password: '',
    },
  });
  const [showForm, setShowForm] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const { handleSubmit, reset, setError } = formContext;

  const handleOpen = () => setShowForm(true);
  const handleClose = () => {
    setShowForm(false);
    setTimeout(() => {
      setShowSuccess(false);
      reset();
    }, 100);
  };

  const handleReset = (data: FormInputs) => {
    resetPassword(data)
      .then(() => {
        setShowSuccess(true);
      })
      .catch((ajaxError) => {
        console.log('ajaxError: ', ajaxError);
        const error = ajaxError?.response?.data;
        const message: string = error?.debug_message?.error || '';
        console.error('Reset password error: ', error?.debug_message);
        setError('email', { message });
      });
  };

  const { loggedIn } = React.useContext(UserContext);

  return (
    <>
      {!loggedIn && (
        <Button
          ref={buttonRef}
          color="inherit"
          onClick={handleOpen}
          variant="text"
          sx={{
            ...(hideButton ? { display: 'none' } : {}),
          }}
        >
          Reset Password
        </Button>
      )}

      <Dialog onClose={handleClose} open={showForm}>
        <DialogTitle>Reset password</DialogTitle>

        <FormContainer
          formContext={formContext}
          handleSubmit={handleSubmit(handleReset)}
        >
          <Collapse in={!showSuccess} timeout="auto" unmountOnExit>
            <DialogContent>
              <Stack spacing={2}>
                <DialogContentText id="alert-dialog-description">
                  Enter your email and we'll send you a link to reset your
                  password
                </DialogContentText>
                <TextFieldElement
                  fullWidth
                  // TODO: add FE validation of email
                  name="email"
                  label="Email address"
                  required
                  type="email"
                />
              </Stack>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose}>Close</Button>

              <Button variant="contained" type="submit">
                Send link to email
              </Button>
            </DialogActions>
          </Collapse>
          <Collapse in={showSuccess} timeout="auto" unmountOnExit>
            <DialogContent>
              <Alert severity="success">
                <Stack spacing={2}>
                  <AlertTitle>Email sent</AlertTitle>
                  <Typography variant="body2">
                    Check your email and open the link we set to continue.
                  </Typography>
                  <Typography variant="body2">
                    <strong>Important</strong>, email link is only valid for 5
                    min.
                  </Typography>
                </Stack>
              </Alert>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </Collapse>
        </FormContainer>
      </Dialog>
    </>
  );
};
