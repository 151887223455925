import * as React from 'react';
import { Box, Button, Card, CardActions, CardContent } from '@mui/material';
import { reviewService } from '../api';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';

interface Props {
  reviewId: string;
  value?: string | null;
  onClose?: () => void;
}

interface FormInputs {
  message: string;
  smeId: number;
}

export const SendMessage: React.FC<Props> = ({ reviewId, value, onClose }) => {
  const { answerReview, reviewRefetch } = reviewService(reviewId);
  const formContext = useForm<FormInputs>({
    defaultValues: {
      message: '',
    },
  });
  const { handleSubmit, reset, setValue } = formContext;

  React.useEffect(() => {
    if (value) {
      setValue('message', value);
    }
  }, [value]);

  const handleSubmitReview = ({ message }: FormInputs) => {
    console.log('message: ', message);
    answerReview({
      uid: reviewId,
      reviewer_note: message,
    }).then(() => {
      reviewRefetch();
      reset();
      if (onClose) {
        onClose();
      }
    });
  };

  return (
    <Box sx={{ marginBottom: 2 }}>
      <FormContainer
        formContext={formContext}
        handleSubmit={handleSubmit(handleSubmitReview)}
      >
        <Card elevation={2} sx={{ maxWidth: '50%' }}>
          <CardContent>
            <TextFieldElement
              name="message"
              label="Message"
              fullWidth
              required
              rows={4}
              about=""
              multiline
            ></TextFieldElement>
          </CardContent>
          <CardActions>
            {!!value && <Button onClick={onClose}>Close</Button>}
            <Button variant="contained" type="submit">
              Send Message
            </Button>
          </CardActions>
        </Card>
      </FormContainer>
    </Box>
  );
};
