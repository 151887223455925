import * as React from 'react';
import { Autocomplete, FormControl, Stack, TextField } from '@mui/material';
interface OptionType {
  inputValue?: string;
  title: string;
}

export type OptionsType = OptionType[];

interface Props {
  label: string;
  optionList?: OptionsType;
  selected?: OptionsType;
  allowCustom?: boolean; // Allow to add custom item
  onChange?: (selectedOptions: OptionsType) => void;
}

export const MultiSelect: React.FC<Props> = ({
  label,
  optionList = [],
  selected,
  allowCustom,
  onChange,
}) => {
  const id = label.replace(' ', '-');
  const [selectedOptions, setSelectedOptions] = React.useState<OptionsType>([]);

  React.useEffect(() => {
    setSelectedOptions(selected || []);
  }, [selected]);

  return (
    <Stack spacing={2}>
      <FormControl fullWidth>
        <Autocomplete
          multiple
          value={selectedOptions}
          id={id}
          onChange={(event, newValue) => {
            const value = newValue as OptionsType;
            setSelectedOptions(value);
            if (onChange) {
              onChange(value);
            }
          }}
          filterOptions={(options, { inputValue }) => {
            const filtered = options.filter((option) =>
              option.title.includes(inputValue),
            );

            if (allowCustom && inputValue) {
              filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={optionList}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.title;
          }}
          renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            return (
              <li key={key} {...optionProps}>
                {option.title}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField {...params} label={label} placeholder="Add" />
          )}
        />
      </FormControl>
    </Stack>
  );
};
