import * as React from 'react';
import {
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';

interface Props extends React.PropsWithChildren {
  mdContent: string;
}

export const MuiMarkDown: React.FC<Props> = ({ mdContent, ...rest }) => {
  return (
    <ReactMarkdown
      {...rest}
      components={{
        // *********
        // * Links *
        // *********
        a: ({ href, title, children }) => (
          <Link href={href} underline={'always'}>
            {children}
          </Link>
        ),

        // ********
        // * Text *
        // ********
        p: ({ children }) => (
          <Typography gutterBottom variant="body1">
            {children}
          </Typography>
        ),
        del: ({ children }) => (
          <Typography sx={{ textDecoration: 'line-through' }}>
            {children}
          </Typography>
        ),
        em: ({ children }) => (
          <Typography sx={{ fontStyle: 'italic' }}>{children}</Typography>
        ),
        strong: ({ children }) => (
          <Typography sx={{ fontWeight: 'bold' }}>{children}</Typography>
        ),
        b: ({ children }) => (
          <Typography sx={{ fontWeight: 'bold' }}>{children}</Typography>
        ),
        // h1: ({ children }) => (
        //   <Typography gutterBottom variant={'h1'}>
        //     {children}
        //   </Typography>
        // ),
        // h2: ({ children }) => (
        //   <Typography gutterBottom variant={'h2'}>
        //     {children}
        //   </Typography>
        // ),
        // h3: ({ children }) => (
        //   <Typography gutterBottom variant={'h5'}>
        //     {children}
        //   </Typography>
        // ),
        // h4: ({ children }) => (
        //   <Typography gutterBottom variant={'h4'}>
        //     {children}
        //   </Typography>
        // ),
        // h5: ({ children }) => (
        //   <Typography gutterBottom variant={'h5'}>
        //     {children}
        //   </Typography>
        // ),
        // h6: ({ children }) => (
        //   <Typography gutterBottom variant={'h6'}>
        //     {children}
        //   </Typography>
        // ),

        h3: ({ children }) => (
          <Typography gutterBottom variant={'h6'}>
            {children}
          </Typography>
        ),

        // **********
        // * Tables *
        // **********
        table: ({ children }) => (
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table size="small">{children}</Table>
          </TableContainer>
        ),
        tbody: ({ children }) => <TableBody>{children}</TableBody>,
        // th: ({ children }) => (<TableHead>{children}</TableHead>),
        tr: ({ children }) => <TableRow>{children}</TableRow>,
        td: ({ children }) => (
          <TableCell>
            <Typography>{children}</Typography>
          </TableCell>
        ),

        // *********
        // * Lists *
        // *********
        ol: ({ children }) => (
          <List
            sx={{
              listStyleType: 'decimal',
              mt: 2,
              p: 0,
              pb: 1,
              pl: 2,
              '& .MuiListItem-root': {
                display: 'list-item',
              },
            }}
          >
            {children}
          </List>
        ),
        ul: ({ children }) => (
          <List
            sx={{
              listStyleType: 'disc',
              mt: 0,
              p: 0,
              pb: 1,
              pl: 2,
              '& .MuiListItem-root': {
                display: 'list-item',
              },
            }}
          >
            {children}
          </List>
        ),
        li: ({ children, ...props }) => (
          <ListItem sx={{ m: 0, p: 0, ml: 2 }} disableGutters>
            <ListItemText sx={{ pl: 0.25 }}>{children}</ListItemText>
          </ListItem>
        ),
      }}
    >
      {mdContent}
    </ReactMarkdown>
  );
};
