import * as React from 'react';
import Box from '@mui/material/Box';
import {
  FormControl,
  FormHelperText,
  IconButton,
  IconContainerProps,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Rating,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { feedbackService } from '../api';

const customIcons: {
  [index: string]: {
    icon: React.ReactElement<unknown>;
    label: string;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="warning" />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: 'Satisfied',
  },
  4: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Very Satisfied',
  },
};

const StyledRating = styled(Rating)(({ theme }) => ({
  gap: '4px',
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const IconContainer = (props: IconContainerProps) => {
  const { value, ...other } = props;
  return <Box {...other}>{customIcons[value].icon}</Box>;
};

interface Props {
  defaultValue: number | null;
  defaultComment: string | null;
  uid: string | null;
  quid: string;
  onSubmitSuccess?: () => void;
}

export const FeedBack: React.FC<Props> = ({
  defaultValue = 0,
  defaultComment = '',
  uid,
  quid,
  onSubmitSuccess,
}) => {
  const [comment, setComment] = React.useState(defaultComment);
  const [value, setValue] = React.useState(defaultValue);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const { sendFeedback } = feedbackService();
  const canEdit = !defaultValue && !isSubmitted;
  const canSubmit = !!comment && !!value;
  const hasError =
    canEdit && (comment !== null || value !== null) && !canSubmit;

  if (!uid) {
    return null;
  }

  const handleSendFeedback = () => {
    sendFeedback({
      uid,
      quid,
      comment: comment || '',
      lickert: value || 1,
    }).then(() => {
      setIsSubmitted(true);
      if (onSubmitSuccess) {
        onSubmitSuccess();
      }
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      handleSendFeedback();
    }
  };

  const IconContainer = (props: IconContainerProps) => {
    const { value, ...other } = props;
    return (
      <Box sx={{ gap: '4px' }} {...other}>
        {customIcons[value].icon}
      </Box>
    );
  };

  return (
    <Box paddingLeft="42px" sx={{ width: '100%' }}>
      <Box pb={2} textAlign="center" display="flex">
        {/* Feedback: */}
        <Tooltip title="Rate the answer.">
          <StyledRating
            name="highlight-selected-only"
            defaultValue={2}
            max={4}
            IconContainerComponent={IconContainer}
            getLabelText={(value: number) => customIcons[value].label}
            highlightSelectedOnly
            onChange={(e: React.SyntheticEvent, value: number | null) => {
              canEdit && setValue(value);
            }}
            value={value}
            readOnly={!canEdit}
          />
        </Tooltip>
      </Box>
      {!canEdit && <Typography>{comment}</Typography>}
      {value && canEdit && (
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="comment">Comment</InputLabel>
          <OutlinedInput
            id="comment"
            value={comment}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setComment(e.target.value)
            }
            onKeyDown={handleKeyDown}
            error={hasError}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  disabled={!canSubmit}
                  onClick={handleSendFeedback}
                  edge="end"
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
          {hasError && (
            <FormHelperText error={hasError} id="comment-helper-text">
              Please select you rating and write a comment.
            </FormHelperText>
          )}
        </FormControl>
      )}
    </Box>
  );
};
